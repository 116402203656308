





















































































































































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import ControlButton from '../ControlButton.vue';
import PlaybackScrubber from '../PlaybackScrubber.vue';
import { SubscriptionManager } from '@/core/utils';
import { PlayerController, PlayerState } from '@/services/player-controller';

import { onKeyDown } from '@/util/keyboard-shortcuts';
import { config } from '@/config';

@Component({
  components: {
    ControlButton,
    PlaybackScrubber,
  },
})
export default class PlayerV2 extends Vue implements VueComponentLifecycle {
  state: PlayerState = {};
  currentTime = 0;
  audioDuration = 0;
  buffered = 0;

  @Inject() readonly os!: string;
  @Inject() private controller!: PlayerController;
  private subscriptions!: SubscriptionManager;

  get disableControls(): boolean {
    return !!(this.state.loading || this.state.suspended);
  }

  get playing(): boolean {
    return !!this.state.playing;
  }

  get thumbnailSrc(): string {
    return this.state.publisher
      ? this.state.publisher.display.thumbnail.small ||
          this.state.publisher.display.thumbnail.default
      : '';
  }

  get trackTitle(): string {
    return this.state.story ? this.state.story.content.title : '';
  }

  get thumbnailAltTag(): string {
    return this.state.publisher && this.state.publisher.channelName
      ? `${this.state.publisher.channelName} Thumbnail`
      : 'Thumbnail';
  }

  ariaLabel(icon: string): string {
    if (icon === 'play') {
      return `From ${this.state.publisher?.name}:${this.trackTitle}, ${
        config.aria.labels[`play${this.os}`]
      }`;
    }

    return config.aria.labels[icon + this.os];
  }

  handleClick(e: Event): void {
    const button = e.target as HTMLElement;

    if (button.getAttribute('aria-disabled')) {
      return;
    }

    if (button.id === 'next') {
      this.controller.next();
    } else if (button.id === 'prev') {
      this.controller.previous();
    } else if (button.id === 'play-container') {
      this.playing ? this.controller.pause() : this.controller.play();
    }
  }

  onKey(e: KeyboardEvent): void {
    const button = e.target as HTMLElement;

    if (button.getAttribute('aria-disabled')) {
      return;
    }

    const playButton = button.id === 'play-container';

    if (playButton && (e.code === 'Enter' || e.code === 'Space')) {
      this.playing ? this.controller.pause() : this.controller.play();
    } else {
      return onKeyDown(e, this.controller, this.state, this.os);
    }
  }

  /* lifecycle */

  created() {
    this.subscriptions = new SubscriptionManager();
    this.state = this.controller.state;
  }

  mounted() {
    this.subscriptions.watch<Partial<PlayerState>>(
      this.controller.updates$,
      update => {
        this.state = { ...this.state, ...update };

        if (update.audioDuration) {
          this.audioDuration = update.audioDuration;
        }

        if (update.buffered) {
          this.buffered = update.buffered;
        }
      }
    );
    this.subscriptions.watch<number>(
      this.controller.currentTime$,
      currentTime => {
        this.currentTime = currentTime;
      }
    );
  }

  destroyed(): void {
    this.subscriptions.close();
  }
}
