import { PlayerController, PlayerState } from '@/services/player-controller';

export function onKeyDown(
  e: KeyboardEvent,
  controller: PlayerController,
  state: PlayerState,
  os: string
) {
  const numStrings = [
    'Digit0',
    'Digit1',
    'Digit2',
    'Digit3',
    'Digit4',
    'Digit5',
    'Digit6',
    'Digit7',
    'Digit8',
    'Digit9',
  ];

  if (e.code === 'KeyK' && e.altKey) {
    state.playing ? controller.pause() : controller.play();
  } else if (e.code === 'KeyN' && e.altKey) {
    if (state.hasNext) {
      controller.next();
    }
  } else if (e.code === 'KeyP' && e.altKey) {
    if (state.hasPrevious) {
      controller.previous();
    }
  } else if (e.code === 'ArrowLeft') {
    if (controller.currentTime >= 5) {
      controller.seekRelative(-5);
    }
  } else if (e.code === 'ArrowRight') {
    if (controller.duration - controller.currentTime > 5) {
      controller.seekRelative(5);
    }
  } else if (e.code === 'KeyJ' && e.altKey) {
    if (controller.currentTime >= 10) {
      controller.seekRelative(-10);
    }
  } else if (e.code === 'KeyL' && e.altKey) {
    if (controller.duration - controller.currentTime > 10) {
      controller.seekRelative(10);
    }
  } else if (e.code === 'KeyH' && e.altKey) {
    readHelp(os);
  } else if (numStrings.includes(e.code) && e.altKey) {
    const numString = e.code[5];
    controller.seekProportionately(parseInt(numString) / 10);
  }
}

function readHelp(os: string): void {
  const el = document.createElement('div');
  el.setAttribute('aria-live', 'polite');
  el.classList.add('sr-only');

  document.body.appendChild(el);

  const modifier = os === 'Mac' ? 'Option' : 'Alt';
  const helpText = `SpokenLayer Web Player Keyboard Shortcuts : To play or pause, use ${modifier}-K. To skip to the next track, use ${modifier}-N. To skip to the previous track, use ${modifier}-P. To seek backward, use Left Arrow or ${modifier}-J. To seek forward, use Right Arrow or ${modifier}-L. To increase playback speed, use ${modifier}-Right Bracket. To decrease playback speed, use ${modifier}-Left Bracket`;

  // eslint-disable-next-line no-console
  console.table([
    {
      'SpokenLayer Web Player Action': 'Play/Pause',
      'Keyboard Shortcut': `${modifier}-K`,
    },
    {
      'SpokenLayer Web Player Action': 'Next Track',
      'Keyboard Shortcut': `${modifier}-N`,
    },
    {
      'SpokenLayer Web Player Action': 'Previous Track',
      'Keyboard Shortcut': `${modifier}-P`,
    },
    {
      'SpokenLayer Web Player Action': 'Seek backward 10 seconds',
      'Keyboard Shortcut': `${modifier}-J`,
    },
    {
      'SpokenLayer Web Player Action': 'Seek forward 10 seconds',
      'Keyboard Shortcut': `${modifier}-L`,
    },
    {
      'SpokenLayer Web Player Action': 'Seek backward 5 seconds',
      'Keyboard Shortcut': 'Left Arrow',
    },
    {
      'SpokenLayer Web Player Action': 'Seek forward 5 seconds',
      'Keyboard Shortcut': 'Right Arrow',
    },
    {
      'SpokenLayer Web Player Action': 'Playback speed -25%',
      'Keyboard Shortcut': `${modifier}-Left Bracket`,
    },
    {
      'SpokenLayer Web Player Action': 'Playback speed +25%',
      'Keyboard Shortcut': `${modifier}-Right Bracket`,
    },
    {
      'SpokenLayer Web Player Action': 'Help',
      'Keyboard Shortcut': `${modifier}-H`,
    },
  ]);

  window.setTimeout(function() {
    el.innerHTML = helpText;
  }, 100);

  window.setTimeout(function() {
    document.body.removeChild(el);
  }, 1000);
}
